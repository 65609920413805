.ui-corner-all {
  -moz-border-radius: 4px 4px 4px 4px; }

.ui-widget-content {
  border: 1px solid black;
  color: black;
  background-color: none; }

.ui-widget {
  font-family: Verdana, Arial, sans-serif;
  font-size: 15px; }

.ui-menu {
  display: block;
  float: left;
  list-style: none outside none;
  margin: 0;
  padding: 0px; }

.ui-autocomplete {
  cursor: default;
  position: absolute;
  max-height: 250px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  z-index: 999999; }

.ui-menu .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%; }

.ui-menu .ui-menu-item a {
  display: block;
  padding: 3px 3px 3px 3px;
  text-decoration: none;
  cursor: pointer;
  background-color: #f2f2f2;
  color: #333; }

.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-focus a:hover {
  display: block;
  padding: 3px 3px 3px 3px;
  text-decoration: none;
  cursor: pointer;
  background-color: #333;
  color: white; }

.ui-menu .ui-menu-item a:hover {
  display: block;
  padding: 3px 3px 3px 3px;
  text-decoration: none;
  color: White;
  cursor: pointer;
  background-color: ButtonText; }

.ui-widget-content a {
  color: #222222; }

.ui-helper-hidden-accessible {
  display: none; }

.ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited {
  color: #212121;
  text-decoration: none; }

