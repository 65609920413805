#erreur-globale {
    padding: 0;
    min-height: 0;
    max-width: 60rem;
    left: 0;
    right: 0;
    margin: auto;
}

    #erreur-globale .padding {
        padding: 0 1.25rem 0 1.25rem;
    }

    #erreur-globale .error-wrapper {
        margin: 2.5rem 0 0 0;
    }

    #erreur-globale .bg {
        background: url(/Images/bg-form-livraison.png) repeat-x top left;
        padding: 0.625rem 1.25rem 0 1.25rem;
    }

    #erreur-globale .error-title {
        color: #d21e24;
        font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1.25rem;
        margin: 0.3125rem 0 1.25rem 0;
    }

    #erreur-globale .error-icon {
        margin-bottom: 1.25rem;
        font-family: 'ElegantIcons';
        color: #808080;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.5rem;
        vertical-align: middle;
    }

    #erreur-globale .error-text {
        font-size: 1rem;
        /* border-bottom: 1px solid #b3b3b3;*/
        text-align: center;
        padding-bottom: 0.625rem;
        margin-bottom: 1.5rem;
        font-family: 'Montserrat', Arial, Helvetica, sans-serif;
        color: #d21e24;
    }

    #erreur-globale .button {
        position: relative;
        top: auto;
        right: auto;
        display: block;
        margin: 0 auto 1.25rem auto;
        background: #4d4d4d;
        color: #fff;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        font-size: 0.6875rem;
        width: 30%;
    }

        #erreur-globale .button:hover {
            background: #333;
        }

@media only screen and (max-width: 40em) {
    #erreur-globale .padding {
        padding: 0 0.625rem 0 0.625rem;
    }

    #erreur-globale .bg {
        padding: 0.625rem 0.625rem 0 0.625rem;
    }

    #erreur-globale .error-title {
        margin: 0.3125rem 0 0.625rem 0;
    }
}

dialog .close-reveal-modal, .reveal-modal .close-reveal-modal {
    font-family: 'ElegantIcons';
    font-size: 1.5em;
    font-weight: normal;
}
